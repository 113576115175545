.title {
  font-size: 1.75rem;
  color: #476575;
}
.add-btn {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.add-btn:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #28a745;
}
.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.input-row label {
  min-width: 100px;
}

.user-profile .link {
  color: #059bbf;
  cursor: pointer;
}

.user-profile .link:hover {
  color: #007bff;
}

.table-header {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 5px 0;
}

.table-row {
  padding: 5px 0;
}

.action-button {
  border-radius: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}

.margin-10 {
  margin-bottom: 10px;
}

.button-edit {
  background-color: #059bbf;
  color: white;
  border: 1px solid #059bbf;
}

.button-delete {
  background-color: #f50057;
  color: white;
  border: 1px solid #f50057;
}

.button-edit:hover {
  color: #059bbf;
  background-color: transparent;
}

.button-delete:hover {
  color: #f50057;
  background-color: transparent;
}

.display-flex {
  display: flex;
  gap: 100px;
}

.role-form {
  margin-top: 20px;
}

.role-input {
  width: 50%;
  margin-bottom: 50px;
}

.user__input {
  width: 150px;
}

.permission-role {
  background-color: white;
}
