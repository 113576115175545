.calender-container {
  background-color: #fff;
  box-shadow: -2px 2px 11px -1px rgba(74, 94, 113, 1);
  border-radius: 0.2rem;
}

.leave-container {
  background-color: #fff;
  box-shadow: -2px 2px 11px -1px rgba(74, 94, 113, 1);
  border-radius: 0.2rem;
  margin-top: 2rem;
  max-width: 500px;
}

.title {
  color: #f8f9fa;
  background-color: #4a5e71;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
}

.calender-wrapper {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.cal-wrap {
  max-width: 450px;
}

.mp-1 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.red {
  color: red;
}

.mrp5 {
  margin-right: 0.5rem;
}

.number-field-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-wrap {
  min-width: 170px;
  margin-left: 0.8rem;
}

.react-calendar {
  width: 450px;
  /* max-height: 362px; */
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  /* border: 1px solid #059bbf; */
  border: none;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-size: auto;
}

.react-calendar button:enabled {
  /* cursor: pointer; */
  /* border: 1px solid red; */
  font-size: large;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  padding: 5px 0;
  border: 1px solid #e6e6e6;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

abbr {
  text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  /* font-size: 0.75em; */
  /* font-weight: bold; */
}

.react-calendar__month-view__days__day--weekend {
  /* color: #3337; */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  /* padding: 2em 0.5em; */
}

.react-calendar__tile {
  background: none;
  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 80px;
  height: 80px;
  overflow: hidden;
  font-weight: none;
  font-size: 0.8rem;
  border: 2px solid #f0f0f0;
}

.highlight-weekend {
  font-weight: bold;
  font-size: 0.8rem;
}

/* // disable date  */
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #3337;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* background: #1087ff; */
  border: 2px solid #f0f0f0;
}

.highlight {
  background-color: #90ee9044;
  color: black;
}

.current-highlight {
  background-color: #6765f644;
  color: black;
}
