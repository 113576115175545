.react-calendar {
  min-width: 100%;
  background: transparent;
  border: 1px solid transparent;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border: transparent;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: transparent;
  font-size: auto;
}

.react-calendar button:enabled {
  font-size: large;
}

.react-calendar button:enabled:hover {
  cursor: text;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: transparent;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-decoration: transparent;
  font-weight: bold;
  font-size: 1rem;
  padding: 5px 0;
  border: 1px solid transparent;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

abbr {
  text-decoration: transparent;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: transparent;
}

.react-calendar__tile {
  background: transparent;
  text-align: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 80px;
  height: auto;
  overflow: hidden;
  font-weight: transparent;
  font-size: 0.8rem;
  border: none;
}

.highlight-weekend {
  font-weight: bold;
  font-size: 0.8rem;
}

/* // disable date  */
.react-calendar__tile:disabled {
  background: transparent;
  color: #3337;
}

.react-calendar__tile--hasActive {
  background: transparent;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: transparent;
}

.react-calendar__tile--active {
  background: transparent;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: transparent;
  border: transparent;
}

.highlight {
  background: transparent;
  /* color: black; */
}

.current-highlight {
  background: transparent;
  /* color: black; */
}

.rbc-month-row {
  height: 37.5rem !important;
}