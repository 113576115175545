select {
  border: 1px solid #ced4da;
  color: #495057;
}

input {
  border: 1px solid #ced4da;
  font-size: 1rem;
}

label {
  color: #495057;
}

.employee-header {
  display: flex;
  border-bottom: 1px solid #6c757d;
  margin-bottom: 1rem;
}

h4 {
  color: #204ca0;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 0;
}

.employee-sublink {
  text-decoration: none;
  padding: 8px 16px;
  color: #868e96;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  padding: 0.5rem 1rem;
  margin-right: 4px;
  background: transparent !important;
}

.new-employee {
  display: flex;
  margin-bottom: 10px;
}

.wrap-employee {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

.wrap-employee label {
  min-width: 120px;
  display: flex;
}

.wrap-employee input {
  width: 50%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-left: 30px;
}

.employee-label {
  width: 17rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  text-transform: capitalize;
}
.employee-label-extra {
  /* width: 10rem; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  text-transform: capitalize;
}

.employee-input {
  width: 70%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.employee-checkbox {
  display: flex;
  margin-bottom: 30px;
}

.employee-checkbox input {
  width: 15px;
  height: auto;
}

.employee-checkbox label {
  padding-left: 15px;
}

.cancel-save-button {
  display: flex;
}

.cancel-save-button button {
  cursor: pointer;
  color: #fff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.cancel-save-button .cancel-btn {
  background-color: #6c757d;
}

.cancel-save-button .save-btn {
  background-color: #087ca7;
  margin-left: 10px;
}

.regular-hours h4 {
  margin-top: 1.5rem !important;
  color: #204ca0;
  font-size: 1rem;
  font-weight: bold;
}

.postal-code-text {
  width: 120px;
}

.postal-code-input {
  width: 15%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.hourly-paid {
  margin-top: 20px;
}

.regular-working-hours input {
  width: 20px;
}
.small {
  position: relative;
  font-size: 14px;
  max-height: 10px;
  bottom: 10px;
  color: #6c757d;
}
.regular-working-hours label {
  margin: 8px 50px 0 8px;
  width: 10px;
}
.full-days-per-week .price {
  margin-left: 15px;
  margin-bottom: 7px;
}

.full-days-per-week .price-override {
  cursor: pointer;
  border: none;
  color: #059bbf;
  font-weight: 600;
  margin: 0 1rem;
}

.edit-price {
  width: 100px;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin: 0 1rem;
}

.regular-working-hours .hours-label {
  margin: 0 3rem;
}

.regular-working-hours .add-partial-hours {
  width: 100px;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-left: 1rem;
}

.alphaNumeric-error {
  color: red;
  margin-left: 25%;
  margin-top: -10px;
  font-size: 80%;
  font-weight: 400;
}

.pay-frequency {
  width: auto;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background: #fff;
  appearance: auto !important;
}

.optional-text {
  margin-left: 25%;
  color: #6c757d !important;
  font-size: 80%;
  font-weight: 400;
}

.email {
  display: flex;
}

.payment-method {
  margin-top: 27px;
  margin-bottom: 30px;
  display: flex;
}

.schedule {
  display: flex;
  margin-top: 25px;
}

.regular-hours {
  margin-bottom: 0px;
  display: flex;
}

.legal-name-format {
  display: flex;
}

.first-name-row {
  display: flex;
  margin-bottom: 30px;
  margin-top: 27px;
}

.hero_header h2 {
  color: #476575;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0.5rem;
  display: inline-block;
}

.add-new-employee-block {
  margin-top: 20px;
}

.address-row-1-col-1 {
  display: flex;
  width: 40%;
}

.address-row-2-col-1 {
  display: flex;
  width: 40%;
}

.address-row-1-col-2 {
  display: flex;
  width: 40%;
}

.address-row-2-col-2 {
  display: flex;
  width: 40%;
}

.employee-input .define-width {
  width: 20%;
}

input {
  text-transform: capitalize;
}
