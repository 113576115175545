.header-wrapper {
  height: 50px;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  background-color: #283a44;
}

.header-wrapper .section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
}

.header-wrapper .section.left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.section .switch-btn {
  border: none;
  color: #0f6674;
  background-color: transparent;
  font-size: 1.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.switch-btn:hover {
  color: #007bff;
  text-decoration: underline;
}

.header-wrapper:hover .switch-btn {
  display: block;
}

.section .input {
  padding: 0.3rem 0.6rem;
  height: 1.8rem;
  font-size: 1rem;
  width: 12rem;
}

.section .navbar-icon-wrap {
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.company-name {
  font-size: 20px;
  margin-right: 5px;
  color: #bdbdbd;
}

.no-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-item {
  padding: 0.5rem 0.2rem;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
}

.item {
  text-decoration: none;
  color: #212529;
}

.nav-item:hover {
  background-color: #fff;
}

.company-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #bdbdbd;
}

.company-btn:hover {
  color: white;
}
