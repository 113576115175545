input,
select {
  padding: 0.3rem;
  font-size: 1rem;
}
/* .span-link {
  color: #000;
}
.span-link:hover {
  color: red;
  text-decoration: none;
} */
