/* Company Manage Style  Start*/
.dashboard-container {
  display: flex;
  min-height: calc(100vh - 50px);
  justify-content: space-between;
  background-color: #f1f1f1;
}

.dashboard-left-section {
  padding-top: 10px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #283a44;
}

.link-menu-item {
  display: flex;
  padding: 5px 1rem;
  cursor: pointer;
}

.link-menu-item:hover .link {
  color: #28a745;
}

.link {
  text-decoration: none;
  color: rgb(189, 189, 189);
  font-size: 1rem;
  padding-bottom: 10px;
  text-decoration: none;
  text-align: center;
  height: 25px;
  padding-left: 5px;
}

.sublink {
  text-decoration: none;
  color: rgb(189, 189, 189);
  font-size: 1rem;
  padding-bottom: 10px;
  margin-left: 2.5rem;
  display: block;
}

@media (max-width: 1440px) {
  .link-menu-item {
    padding: 0.2rem 0.4rem;
  }

  .sublink {
    margin-left: 2rem;
  }
}

@media (max-width: 1024px) {
  .link-menu-item {
    padding: 0.2rem 0.2rem;
  }

  .sublink {
    margin-left: 1.5rem;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.sublink:hover {
  color: #28a745;
}

.dashboard-right-section {
  padding: 10px;
  padding-left: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  overflow-x: scroll;
}

.company-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.company-header-wrap h2 {
  font-size: 1.5rem;
  color: #476575;
}

.company-header-wrap button {
  width: 200px;
  height: 40px;
  font-size: 20px;
  margin: 5px;
  padding: 0;
}

.table {
  overflow-x: auto;
}

.company-table {
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
}

.company-table-head {
  font-size: 1rem;
}

.company-table td,
.company-table th,
.company-table tr {
  padding: .3rem;
  font-size: 13px;
  text-align: left;
  color: #333;
}

.company-table-log td,
.company-table-log th,
.company-table-log tr {
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  text-align: left;
  color: #333;
}

.company-table tbody {
  border-left: 1px solid #4a5e71;
  border-right: 1px solid #4a5e71;
  border-bottom: 1px solid #4a5e71;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.company-table th {
  background-color: #4a5e71;
  color: #f8f9fa;
  border: none;
}

.company-table tr {
  padding: 10px;
  font-weight: 400;
  border: none;
}

.company-table tr:nth-child(even) {
  background-color: #e0e0e0;
}

tr td input {
  width: 100%;
  height: 30px;
  font-size: 1rem;
  padding: 0 0 0 0.3rem;
  margin: 0;
  border-color: #476575;
  background-color: transparent;
}

tr td input:hover {
  background-color: #fff;
}

.company-table tr:hover {
  background-color: #fff;
  color: #333;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  -webkit-box-shadow: -2px -1px 5px 0px #283a44;
  -moz-box-shadow: -2px -1px 5px 0px #283a44;
  box-shadow: -2px -1px 5px 0px #283a44;
}

.company-table span.td-link {
  color: #059bbf;
  cursor: pointer;
}

.company-table span.td-link:hover {
  color: #007bff;
}

.number-3 {
  width: 50px;
  max-width: 100px;
}

.number-6 {
  width: 100px;
  max-width: 200px;
}

.text-input {
  min-width: 200px;
}

.update-btn {
  margin: 0;
  width: 70px;
  font-size: 1rem;
  padding: 3px;
  font-weight: bold;
  border-radius: 5px;
}

.edit-btn {
  width: 70px;
  font-size: 1rem;
  padding: 3px;
  color: #333;
  border-radius: 5px;
}

.company-table tr:nth-child(even):hover {
  background-color: #fff;
  color: #333;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  -webkit-box-shadow: -2px -1px 10px 0px #283a44;
  -moz-box-shadow: -2px -1px 10px 0px #283a44;
  box-shadow: -2px -1px 10px 0px #283a44;
}

.company-table-make-default-btn {
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  color: blue;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.company-table-make-default-btn:hover {
  font-weight: bold;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.company-table-delete-btn {
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
  color: red;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.company-table-delete-btn:hover {
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.company-table .right-align {
  text-align: right;
}

/* Company Manage Style End */

/* Manage CPF Table Style Start */
.cpf-container {
  width: 100%;
}

.manage-pf-wraps {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.warning-wrap {
  border: 1px solid #f00;
  max-width: 550px;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.warning-wrap p {
  color: #f00;
  margin-left: 5px;
}

.warning-wrap .sub-text {
  color: #0009;
}

.manage-pf-wraps h2 {
  font-size: 1.5rem;
  color: #476575;
  width: 100%;
}

.grey {
  color: #0009;
}

.manage-pf-wrap-select select {
  color: #0009;
  font-size: 1rem;
  width: 400px;
  height: 30px;
  border: 1px solid #283a44;
  outline: none;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
}

/* Manage CPF Table Style End */

/* Manage CPF Calculatoe Style Start */
.cal-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.manage-pf-wrap-select .emp-type {
  width: 500px;
  height: 40px;
}

.cal-row-wrap {
  margin-bottom: 20px;
}

.cal-row-wrap label {
  display: block;
  padding-top: 20px;
}

.cal-wrapper span {
  color: #f00;
}

.row-wrap {
  display: flex;
  justify-content: space-between;
}

.cal-input {
  width: 180px;
}

.info-wrap {
  margin: 10px 0;
}

.cal-row-wrap a {
  text-decoration: none;
  color: #283a44;
}

.cal-step {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.result-wrap {
  border: 1px solid #283a44;
  min-width: 500px;
  padding: 20px;
  padding-bottom: 50px;
  padding-right: 50px;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 5px 0px #00000005;
  margin-left: 100px;
  margin-top: 10px;
}

.result-wrap .heading {
  color: #476575;
  font-size: 1.75rem;
  margin-bottom: 30px;
  border-bottom: 3px solid #476575;
}

.result-row-wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #476575;
}

.result-row-wrap .keys {
  padding: 10px;
  font-size: 20px;
  color: #0009;
}

.result-row-wrap .values {
  padding: 10px;
  font-size: 20px;
  color: #333;
}

.cpf-limit-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Manage CPF Calculatoe Style End */
