.add-company-modal {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.line {
  padding-bottom: 20px;
  width: 400px;
  border-top: 3px dashed #f1f1f1;
}

.add-company-modal .header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.add-company-modal .header h2 {
  padding: 0;
  margin-top: 20px;
  padding-left: 50px;
  font-size: 30px;
  color: #283a44;
}

.close-icon {
  cursor: pointer;
}

.add-company-modal .body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.img-preview input {
  width: 300px;
  margin-bottom: 50px;
  padding: 8px;
}

.img-preview img {
  width: 80px;
  height: 80px;
  border: 1px solid #283a44;
  position: absolute;
  margin-left: 20px;
  bottom: 150px;
  border-radius: 50%;
}

label {
  font-size: 16px;
  padding-bottom: 5px;
}

.add-company-modal .footer {
  display: flex;
  align-items: flex-end;
}

.modal-btn {
  width: 190px;
  height: 50px;
  padding: 0;
  margin: 0 10px;
  font-size: 20px;
  font-weight: bold;
}

.textarea {
  width: 400px;
  background: #ffffff;
  border: 1px solid #676ed1;
  border-radius: 4px;
  font-size: 18px;
  padding-left: 8px;
  text-transform: capitalize;
}

.payslip-date {
  display: flex;
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  align-items: center;
}

.payslip-date:hover {
  background-color: #7fbbd1;
}

.date-text {
  margin-left: 1rem;
}

.modal-link {
  font-size: 1.25rem;
  margin-left: 1rem;
  color: #059bbf;
}

.modal-link:hover {
  color: #087ca7;
}

.payslip-modal .header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.payslip-modal .body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.payslip-modal .card {
  width: 45%;
}
.payslip-modal .row {
  display: flex;
  justify-content: space-between;
  min-width: 50%;
}
