.payroll-header-h2 {
  color: #476575;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.employee-sublink {
  color: #868e96;
  font-size: 1rem;
  font-weight: 400;
}

.employee-sublink:hover {
  color: #212529;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 3px solid #25b481;
}

.payroll-header {
  display: flex;
  border-bottom: 1px solid #6c757d;
  margin-bottom: 5px;
}

.employee-payroll .wrap-box {
  margin: 1rem 0;
}

.employee-payroll .text {
  color: #212529;
  font-size: 1rem;
}

.employee-payroll .card-wrapper {
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s all;
}

.employee-payroll .card-container {
  margin-top: 1rem;
  margin-right: 0.2rem;
  max-width: 32%;
  flex-grow: 1;
}

@media screen and (max-width: 1440px) {
  .employee-payroll .card-container {
    margin-top: 1rem;
    margin-right: 0.4rem;
    flex-grow: 1;
  }
}

@media screen and (max-width: 1023px) {
  .employee-payroll .card-container {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1rem;
    margin: 0.25rem;
    min-width: 350px;
    max-width: 100%;
    flex-grow: 1;
  }
}

.employee-payroll .card-container .card-header {
  padding: 0.75rem 1.25rem;
  background-color: #4a5e71;
  color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.2rem;
}

.employee-payroll .card-container .card-header select {
  background-color: transparent;
  border: 1px solid #f8f9fa;
  padding: 0.4rem 0.3rem;
  border-radius: 0.2rem;
  color: #f8f9fa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 60px;
  cursor: pointer;
  outline: none;
}

/* .employee-payroll .card-container .card-header button {
  background-color: transparent;
  border: 1px solid #f8f9fa;
  padding: 0.4rem 0.3rem;
  border-radius: 0.2rem;
  color: #f8f9fa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 60px;
  cursor: pointer;
} */

.employee-payroll .card-container .card-body {
  background-color: #fff;
}

.employee-payroll .card-container .card-body .card-row-container {
  border-left: 1px solid #6c757d;
  border-right: 1px solid #6c757d;
  border-bottom: 1px solid #6c757d;
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-direction: column;
}

.employee-payroll
  .card-container
  .card-body
  .card-row-container
  .card-row-link {
  color: #059bbf;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.link {
  color: #059bbf;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.employee-payroll
  .card-container
  .card-body
  .card-row-container
  .card-row-link:hover {
  color: #007bff;
}

.employee-payroll .card-container .card-body .card-row-container .card-row {
  display: flex;
  justify-content: space-between;
  padding-top: 0.25rem;
}

.employee-payroll
  .card-container
  .card-body
  .card-row-container
  .card-row
  .sm-btn {
  min-width: 80px;
  max-width: 120px;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
  border-radius: 0.2rem;
}

@media screen and (max-width: 1440px) {
  .employee-payroll .card-container .card-body .card-row-container {
    padding: 0.2rem 0.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .employee-payroll .card-container .card-body .card-row-container {
    padding: 0.75rem 1.25rem;
  }
}

.employee-payroll .wrap-box .heading {
  font-size: 1.5rem;
  color: #212529;
}

.employee-payroll ul {
  margin-left: 2rem;
}

.employee-payroll ul li {
  color: #212529;
  font-size: 1rem;
  margin: 0.3rem 0;
}

/* edit-basic-salary style start */
.edit-basic-salary .row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}

.edit-basic-salary .row label {
  font-size: 1rem;
  margin-top: 0.2rem;
  color: #212529;
}

.edit-basic-salary .row input[type="checkbox"] {
  width: 1rem;
  margin-right: 1rem;
}

.edit-basic-salary .row input[type="number"] {
  margin-left: 1rem;
  height: 2rem;
  outline: none;
  background-color: #fff;
  border: 1px solid grey;
}

.edit-basic-salary .row .md-btn {
  width: 200px;
  height: 40px;
  padding: 0.5rem;
  margin: 0;
  margin-top: 2rem;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.edit-basic-salary .text {
  color: #212529;
  margin-top: 1rem;
}

/* edit-basic-salary style end */

/* PayslipCalculation style */
.payslip-container {
  display: flex;
  /* flex-wrap: wrap; */
  /* position: relative; */
}

.payslip-container .card {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  min-width: 400px;
}

.payslip-container .data {
  background-color: #f8f9fa;
  min-height: 50px;
  padding: 0.75rem 1.25rem;
  border-radius: 0.2rem;
}

.payslip-container .card-row-link {
  text-decoration: none;
  color: #059bbf;
}

.payslip-container .card-row-link:hover {
  text-decoration: underline;
  color: #333;
}

.preview-link {
  text-decoration: none;
  color: #059bbf;
  cursor: pointer;
}

.preview-link:hover {
  text-decoration: underline;
  color: #333;
}

.payslip-container .heading {
  background-color: #4a5e71;
  color: #f8f9fa;
}

.divider {
  width: 100%;
  height: 0.5px; 
  background-color: #ccc; 
  margin: 20px 0; 
}

/* PayslipCalculation style end */
/* EndServies style start */
.field-wrapper {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.field-wrapper label {
  width: 30%;
  text-transform: none;
  color: black;
}

/* EndServies style end */
